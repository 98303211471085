<template>
  <div class="is-mobile">
    <div class="is-rounded">
      <div class="card-content">
        <p class="title">Hai buoni pasto o altri bonus?</p>
        <p class="subtitle">Inserisci il valore totale</p>
        <b-field class="is-centered">
          <b-input type="text" v-cleave="masks.money" v-model="bonus" required />
          <p class="control">
            <span class="button is-static">Euro</span>
          </p>
        </b-field>
        <div class="columns">
          <div class="column is-12">
            <b-message type="is-primary">
              Alcune volte, le aziende forniscono ai propri dipendenti alcuni 'benefits' che sono più o meno rilevanti ma che sicuramente devono essere tenuti di conto.
              <br />Forse, il più famoso è il 'buono pasto' in formato cartaceo o elettronico.
            </b-message>
          </div>
        </div>
        <div class="buttons is-centered">
          <b-button type="is-success" size="is-large" @click="submitAltro">{{labelDoneButton}}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";

import CleaveMixin from "@/mixins/CleaveMixin";

export default {
  name: "altro",
  mixins: [CleaveMixin],
  props: ["labelDoneButton"],
  data: function() {
    return {
      bonus: "0"
    };
  },
  methods: {
    submitAltro() {
      if (this.bonus == null || this.bonus == undefined) {
        this.bonus = "0";
      }

      this.$store.dispatch(
        "setBonus",
        parseFloat(this.bonus.replace(",", "."))
      );
      this.$emit("done");
    }
  }
};
</script>