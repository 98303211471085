<script>
import moment from "moment";

export default {
  canLoad(workbook) {
    try {
      return (
        workbook.Sheets[workbook.SheetNames[0]]["B1"].v == "LISTA MOVIMENTI"
      );
    } catch (error) {
      return false;
    }
  },
  load(workbook) {
    try {
      let sheet = workbook.Sheets[workbook.SheetNames[0]];

      let startIndex = (sheet["B14"] == "Data contabile" ? 14 : 15) + 1;
      let endIndex = sheet["!ref"].split(":")[1].substring(1) - 3;

      let saldo = sheet["C6"].v;

      let dati = [];
      for (let i = startIndex; i <= endIndex; i++) {
        dati.push({
          data_contabile:
            sheet["B" + i] != undefined
              ? moment(sheet["B" + i].v, "DD/MM/YYYY").toDate()
              : null,
          data_valuta: moment(sheet["C" + i].v, "DD/MM/YYYY").toDate(),
          importo: Number(
            sheet["E" + i] != undefined && sheet["E" + i].v != ""
              ? sheet["E" + i].v
              : sheet["F" + i].v
          ),
          descrizione: sheet["D" + i] != undefined ? sheet["D" + i].v : null,
          divisa: sheet["G" + i].v
        });
      }

      return { dati: dati, saldo: saldo };
    } catch (error) {
      throw error;
    }
  }
};
</script>