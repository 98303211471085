<template>
  <div class="is-mobile">
    <div class="is-rounded">
      <div class="card-content">
        <div class="columns">
          <div class="column">
            <p class="title">Quanto devi dare?</p>
            <p class="subtitle">Posizione debitoria</p>
            <b-field class="is-centered">
              <b-input type="text" v-cleave="masks.money" v-model="debiti" required></b-input>
              <p class="control">
                <span class="button is-static">Euro</span>
              </p>
            </b-field>
          </div>
          <div class="column">
            <p class="title">Quanto devi avere?</p>
            <p class="subtitle">Posizione creditoria</p>
            <b-field class="is-centered">
              <b-input type="text" v-cleave="masks.money" v-model="crediti" required></b-input>
              <p class="control">
                <span class="button is-static">Euro</span>
              </p>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-12">
            <b-message type="is-primary">
              Per calcolare correttamente la tua situazione finanziaria è necessario sapere le tue posizioni.
              <br />
              <br />
              <p>Ti consigliamo di compilare i campi per avere una situazione più chiara del tuo bilancio.</p>
            </b-message>
          </div>
        </div>
        <div class="buttons is-centered">
          <b-button type="is-success" size="is-large" @click="submitDebitiCrediti">{{labelDoneButton}}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";

import CleaveMixin from "@/mixins/CleaveMixin";

export default {
  name: "debitiCrediti",
  mixins: [CleaveMixin],
  props: ["labelDoneButton"],
  data: function() {
    return {
      debiti: "0",
      crediti: "0"
    };
  },
  methods: {
    submitDebitiCrediti() {
      if (this.debiti == null || this.debiti == undefined) {
        this.debiti = "0";
      }
      if (this.crediti == null || this.crediti == undefined) {
        this.crediti = "0";
      }

      this.$store.dispatch("setDebitiAndCrediti", {
        debiti: parseFloat(this.debiti.replace(",", ".")),
        crediti: parseFloat(this.crediti.replace(",", "."))
      });
      this.$emit("done");
    }
  }
};
</script>