
<template>
  <div class="container is-mobile">
    <div class="is-rounded">
      <div class="card-content">
        <b-field class="is-centered">
          <b-input
            type="text"
            :placeholder="contanti"
            v-cleave="masks.money"
            v-model="contanti"
            required
          />
          <p class="control">
            <span class="button is-static">Euro</span>
          </p>
        </b-field>
        <p
          class="subtitle"
          style="text-align: left"
        >In base alla categorizzazione precedente: {{contantiEstimated}} Euro</p>
        <div class="columns">
          <div class="column is-12">
            <b-message class="is-primary">
              La cifra calcolata è data dai movimenti categorizzati come 'Contanti'.
              <br />Probabilmente, devi indicare quanti di questi te ne sono rimasti.
            </b-message>
          </div>
        </div>
        <div class="buttons is-centered">
          <b-button type="is-success" size="is-large" @click="submitContanti">{{labelDoneButton}}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CleaveMixin from "@/mixins/CleaveMixin";

export default {
  name: "contanti",
  mixins: [CleaveMixin],
  props: ["labelDoneButton"],
  data: function() {
    return {
      contanti: "0"
    };
  },
  computed: {
    contantiEstimated() {
      let estimated = this.$store.getters.datiVisibili
        .filter(
          entry => entry.categoria == this.$store.getters.contantiCategoriaIndex
        )
        .reduce((sum, current) => (sum -= current.importo), 0);

      return parseFloat(Math.round(estimated * 100) / 100).toFixed(2);
    }
  },
  methods: {
    submitContanti() {
      this.$store.commit(
        "addContanti",
        parseFloat(this.contanti.replace(",", "."))
      );
      this.$emit("done");
    }
  }
};
</script>