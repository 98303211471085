<template>
  <section class="container">
    <SelectCategoriaFilter
      :showFlag.sync="showSelectCategoriaFilter"
      :filteredData="filteredDataForCategorizzaFilter"
      @close="showSelectCategoriaFilter=false"
      @on-cancel="showSelectCategoriaFilter=false"
      v-on:selectedCategoria="setCategoriaFilter"
    />

    <nav v-show="!readOnly" class="level centralPanel">
      <div class="level-left">
        <div clas="level-item">
          <b-collapse :open.sync="isFiltriOpen" class="panel">
            <div slot="trigger" slot-scope="props" aria-id="filtri-collapse" class="panel-heading">
              <nav class="level">
                <div class="level-left">
                  <div class="level-item">
                    <b-tooltip
                      label="Questa finestra permette di definire dei criteri su cui filtrare i movimenti nella tabella sottostante."
                      position="is-right"
                      type="is-light"
                      :delay="600"
                      multilined
                      square
                    >
                      <b-icon class="control" icon="information" size="is-small"></b-icon>
                    </b-tooltip>Filtri
                  </div>
                </div>
                <div class="level-right">
                  <div v-show="props.open" class="level-item">
                    <b-button type="is-light" @click="resetFilters" @click.stop>Reset</b-button>
                  </div>
                  <div clas="level-item">
                    <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
                  </div>
                </div>
              </nav>
            </div>

            <div class="panel-block">
              <b-field label="Periodo:" horizontal>
                <b-datepicker
                  :min-date="minDatePeriod"
                  :max-date="maxDatePeriod"
                  v-model="datePeriodFilter"
                  icon="calendar"
                  range
                  nearby-selectable-month-days
                  :years-range="[-100,+100]"
                ></b-datepicker>
                <div class="control is-size-7">
                  <b-switch v-model="showRecent" type="is-success">Ultimi caricati</b-switch>
                </div>
              </b-field>
            </div>

            <div class="panel-block">
              <b-field label="Descrizione:" horizontal>
                <b-input v-model="descrizioneFilter" type="search" icon="magnify" expanded></b-input>
              </b-field>
            </div>

            <div class="panel-block">
              <b-field label="Categoria:" horizontal>
                <b-dropdown hoverable aria-role="list">
                  <button class="button" slot="trigger">
                    <span>{{ categoriaFilterLabel }}</span>
                    <b-icon icon="menu-down"></b-icon>
                  </button>
                  <b-dropdown-item aria-role="listitem" @click="resetCategoriaFilter()">Tutto</b-dropdown-item>
                  <b-dropdown-item
                    aria-role="listitem"
                    @click="showSelectCategoriaFilter = true"
                  >Seleziona categoria...</b-dropdown-item>
                </b-dropdown>
              </b-field>
            </div>
          </b-collapse>
        </div>
      </div>

      <div class="level-right">
        <div class="level-item">
          <b-field>
            <b-tooltip
              class="control"
              label="Operazione di modifica categoria di uno o più movimenti che rispettano certi criteri. L'operazione si applica ai movimenti attualmente visibili secondo il filtro impostato."
              position="is-left"
              type="is-light"
              :delay="600"
              multilined
              square
            >
              <b-icon class="control" icon="information" size="is-small"></b-icon>
            </b-tooltip>
            <b-dropdown class="control" ref="actions" position="is-bottom-left">
              <button class="button is-action-button" slot="trigger" outline>
                <b-icon icon="menu-down" />
                <span>Esegui categorizzazione massiva</span>
              </button>
              <b-dropdown-item class="bulk-action" :focusable="false" custom>
                <form @submit.prevent="applyContainsRule">
                  <b-field label="Se contiene" horizontal>
                    <b-input
                      placeholder="Inserisci una parola qua"
                      v-model="parolaSearchRule"
                      rounded
                    ></b-input>
                  </b-field>
                  <b-field label="Applica categoria" horizontal class="field-horizontal-button">
                    <b-dropdown class="categoria-dropdown" v-model="categoriaRuleIndex">
                      <button
                        type="button"
                        class="button is-primary"
                        slot="trigger"
                      >{{ resolveCategoria(categoriaRuleIndex) }}</button>
                      <template v-for="(categoria, index) in categorie">
                        <b-dropdown-item :value="index" :key="index">{{ categoria.name }}</b-dropdown-item>
                      </template>
                    </b-dropdown>
                  </b-field>
                  <b-field label horizontal class="field-horizontal-button">
                    <b-button
                      class="is-mobile"
                      type="is-warning"
                      native-type="submit"
                      :disabled="!showRuleButton"
                    >Esegui</b-button>
                  </b-field>
                </form>
              </b-dropdown-item>
            </b-dropdown>
          </b-field>
        </div>
      </div>
    </nav>

    <SelectCategoria
      :showFlag.sync="showSelectCategoria"
      @close="showSelectCategoria=false;"
      @on-cancel="showSelectCategoria=false"
      v-on:selectedCategoria="changeCategoria"
    />

    <div class="block">
      <b-table
        :data="this.filteredData"
        :default-sort="['data_valuta']"
        default-sort-direction="desc"
        hoverable
        narrowed
        striped
        :row-class="getRowClass"
        :paginated="true"
        :per-page="20"
        :current-page.sync="this.currentPage"
        :pagination-simple="true"
        pagination-position="bottom"
      >
        <template slot-scope="props">
          <b-table-column
            field="data_valuta"
            label="Data Valuta"
            :class="props.row.hidden ? 'strikethrough is-size-7' : ''"
            centered
            sortable
            class="is-narrow"
          >{{ props.row.data_valuta.toLocaleDateString() }}</b-table-column>

          <b-table-column
            field="data_contabile"
            label="Data Contabile"
            :class="props.row.hidden ? 'strikethrough is-size-7' : ''"
            centered
            sortable
            class="is-narrow"
          >{{ props.row.data_contabile != undefined ? props.row.data_contabile.toLocaleDateString() : "" }}</b-table-column>

          <b-table-column
            field="descrizione"
            label="Descrizione"
            :class="props.row.hidden ? 'strikethrough is-size-7' : ''"
          >{{ props.row.descrizione }}</b-table-column>

          <b-table-column
            field="importo"
            label="Importo"
            :class="props.row.hidden ? 'strikethrough is-size-7' : ''"
            sortable
            numeric
            class="is-narrow"
          >
            <b
              :class="{
              'has-importo-negativo': props.row.importo < 0,
              'has-importo-positivo': props.row.importo >= 0
            }"
            >{{ parseFloat(Math.round(props.row.importo * 100) / 100).toFixed(2) }} Euro</b>
          </b-table-column>

          <b-table-column
            field="categoria"
            custom-key="categoria"
            :class="props.row.hidden ? 'strikethrough is-size-7' : ''"
            class="is-narrow"
            style="min-width: 112px"
          >
            <div slot="header">
              Categoria
              <b-tooltip
                v-if="!readOnly"
                label="Clicca sulla categoria di un movimento per modificarla"
                position="is-top"
                type="is-light"
                :delay="600"
                multilined
                square
                style="margin-left: -12px"
              >
                <b-icon class="control" icon="information" size="is-small"></b-icon>
              </b-tooltip>
            </div>
            <b-button
              type="is-primary"
              size="is-small"
              :disabled="readOnly || props.row.hidden"
              @click="indexButtonSelectCategoria = props.row.id; showSelectCategoria = true"
            >{{ resolveCategoria(props.row.categoria) }}</b-button>
          </b-table-column>

          <b-table-column
            v-if="!readOnly"
            label="Azioni"
            :class="props.row.hidden ? 'strikethrough is-size-7' : ''"
            class="is-narrow"
            centered
          >
            <b-dropdown hoverable aria-role="list">
              <b-button icon-right="dots-horizontal" size="is-small" slot="trigger" rounded></b-button>
              <b-dropdown-item
                v-if="!props.row.hidden"
                aria-role="listitem"
                @click="confermaNascondiDatoModal(props.row.id)"
              >Nascondi</b-dropdown-item>

              <b-dropdown-item
                v-if="props.row.hidden"
                aria-role="listitem"
                @click="confermaMostraDatoModal(props.row.id)"
              >Mostra</b-dropdown-item>
              <!-- <b-dropdown-item
                aria-role="listitem"
              >Aggiungi una nota...</b-dropdown-item>-->
            </b-dropdown>
          </b-table-column>
        </template>

        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon icon="emoticon-sad" size="is-large"></b-icon>
              </p>
              <p>Nessun movimento.</p>
            </div>
          </section>
        </template>
      </b-table>
    </div>

    <transition name="fade">
      <a v-show="showBtt" class="btt" role="button" @click="backToTop" title="Torna in cima">
        <b-icon icon="arrow-up-bold-circle" size="is-large" />
      </a>
    </transition>
  </section>
</template>
   
<script>
import SelectCategoria from "@/components/import/SelectCategoria.vue";
import SelectCategoriaFilter from "@/components/import/SelectCategoriaFilter.vue";
import moment from "moment";

export default {
  name: "categorizza",
  components: {
    SelectCategoria,
    SelectCategoriaFilter
  },
  props: ["readOnly"],
  data: function() {
    return {
      currentPage: 1,
      parolaSearchRule: null,
      isFiltriOpen: true,
      showRecent: true,
      descrizioneFilter: null,
      datePeriodFilter: [],
      categoriaRuleIndex: this.$store.getters.altreSpeseCategoriaIndex,
      showSelectCategoriaFilter: false,
      categoriaFilterIndex: null,
      categoriaFilterLabel: "Tutto",
      showSelectCategoria: false,
      indexButtonSelectCategoria: null,
      updatedDati: [],
      showBtt: false,
      forceReRender: 0
    };
  },
  computed: {
    filteredDataForCategorizzaFilter() {
      let retFilteredData = this.$store.state.dati;
      if (this.showRecent) {
        retFilteredData = retFilteredData.filter(dato => dato.recent == true);
      }
      if (this.descrizioneFilter != null) {
        retFilteredData = retFilteredData.filter(entry => {
          if (entry.descrizione == null) return false;
          return entry.descrizione
            .toLowerCase()
            .includes(this.descrizioneFilter.toLowerCase());
        });
      }
      if (this.datePeriodFilter.length == 2) {
        retFilteredData = retFilteredData.filter(
          d =>
            moment(d.data_valuta).isSameOrAfter(this.datePeriodFilter[0]) &&
            moment(d.data_valuta).isSameOrBefore(this.datePeriodFilter[1])
        );
      }
      return retFilteredData;
    },
    filteredData() {
      let retFilteredData = this.filteredDataForCategorizzaFilter;
      if (this.categoriaFilterIndex != null) {
        retFilteredData = retFilteredData.filter(
          entry => entry.categoria == this.categoriaFilterIndex
        );
      }
      return retFilteredData;
    },
    showRuleButton() {
      return (
        this.parolaSearchRule != null && this.parolaSearchRule.trim() != ""
      );
    },
    categorie() {
      return this.$store.state.categorie;
    },
    minDatePeriod: function() {
      return new Date(
        Math.min.apply(
          null,
          this.$store.state.dati.map(entry => entry.data_valuta)
        )
      );
    },
    maxDatePeriod: function() {
      return new Date(
        Math.max.apply(
          null,
          this.$store.state.dati.map(entry => entry.data_valuta)
        )
      );
    }
  },
  methods: {
    resolveCategoria(index) {
      return this.$store.state.categorie[index].name;
    },
    applyContainsRule() {
      let appliedDati = this.$store.dispatch("applyContainsRule", {
        word: this.parolaSearchRule,
        index: this.categoriaRuleIndex,
        ids: this.filteredData.map(dato => dato.id)
      });
      appliedDati.then(value => {
        this.$buefy.toast.open({
          duration: 6000,
          message: `Aggiornati ${value.length} movimenti con successo`,
          position: "is-bottom",
          type: "is-success"
        });
        this.highlightDati(value);
        this.$refs.actions.toggle();
        this.$emit("changeCategoryRuleExecuted");
      });
    },
    highlightDati(dati) {
      this.updatedDati = dati;
      // reset last update
      setTimeout(() => (this.updatedDati = []), 10 * 1000);
    },
    changeCategoria(categoriaIndex) {
      this.$store.dispatch("changeCategoria", {
        categoriaIndex: categoriaIndex,
        indexDati: this.indexButtonSelectCategoria
      });
    },
    confermaNascondiDatoModal(datoId) {
      this.$buefy.dialog.confirm({
        title: "Conferma nascondi movimento",
        message:
          "<p>Attenzione, vuoi <b>nascondere</b> il movimento?<br/> Non sarà utilizzato nei grafici ed ignorato nella situazione economica e finanziaria.</p> <br> L'azione è reversibile.",
        confirmText: "Nascondi",
        cancelText: "Ho cambiato idea",
        type: "is-warning",
        hasIcon: true,
        onConfirm: () => {
          const loadingComponent = this.$buefy.loading.open();
          this.$store.dispatch("hideDato", datoId).then(() => {
            this.$emit("hideShowAction");
            loadingComponent.close();
            this.forceReRender++;
          });
        }
      });
    },
    confermaMostraDatoModal(datoId) {
      this.$buefy.dialog.confirm({
        title: "Conferma mostra movimento",
        message:
          "<p>Attenzione, vuoi <b>mostrare</b> il movimento?<br/> Sarà utilizzato nei grafici e nella situazione economica e finanziaria.</p> <br> L'azione è reversibile.",
        confirmText: "Mostra",
        cancelText: "Ho cambiato idea",
        type: "is-warning",
        hasIcon: true,
        onConfirm: () => {
          const loadingComponent = this.$buefy.loading.open();
          this.$store.dispatch("showDato", datoId).then(() => {
            this.$emit("hideShowAction");
            loadingComponent.close();
            this.forceReRender++;
          });
        }
      });
    },
    setCategoriaRule(newCategoriaRuleIndex) {
      this.categoriaRuleIndex = newCategoriaRuleIndex;
    },
    setCategoriaFilter(newCategoriaRuleIndex) {
      this.categoriaFilterIndex = newCategoriaRuleIndex;
      this.categoriaFilterLabel = this.resolveCategoria(newCategoriaRuleIndex);
    },
    resetCategoriaFilter() {
      this.categoriaFilterIndex = null;
      this.categoriaFilterLabel = "Tutto";
    },
    resetFilters() {
      this.showRecent = true;
      this.descrizioneFilter = null;
      this.resetDatePeriodInterval();
      this.resetCategoriaFilter();
    },
    getRowClass(row) {
      return this.updatedDati.indexOf(row.id) >= 0
        ? "is-row-updated"
        : "is-row-simple";
    },
    resetDatePeriodInterval() {
      this.datePeriodFilter = [this.minDatePeriod, this.maxDatePeriod];
    },
    backToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth"
      });
    },
    onTrendGraficiClicked(graficiParam) {
      this.resetFilters();

      this.showRecent = false;

      this.datePeriodFilter[0] = graficiParam[0];
      this.datePeriodFilter[1] = graficiParam[1];
    },
    onCategoryGraficiClicked(graficiParam) {
      this.resetFilters();

      this.showRecent = false;

      this.setCategoriaFilter(graficiParam.clickedCategoriaIndex);

      let startDate = new Date(graficiParam.selectedDate.getTime());
      startDate.setDate(graficiParam.monthDayStart);
      this.datePeriodFilter[0] = startDate;

      let endDate = new Date(graficiParam.selectedDate.getTime());
      endDate.setMonth(graficiParam.selectedDate.getMonth() + 1);
      endDate.setDate(graficiParam.monthDayStart - 1);
      this.datePeriodFilter[1] = endDate;
    },
    onTrendCategoryGraficiClicked(graficiParam) {
      this.resetFilters();
      this.showRecent = false;
      this.setCategoriaFilter(graficiParam.category);

      let selectedDate = moment(graficiParam.month);
      this.datePeriodFilter[0] = selectedDate.startOf("month").toDate();
      this.datePeriodFilter[1] = selectedDate.endOf("month").toDate();
    }
  },
  beforeMount() {
    this.resetDatePeriodInterval();
    if (typeof window !== "undefined") {
      document.addEventListener("scroll", () => {
        this.showBtt = window.scrollY > 300;
      });
    }
  }
};
</script>

<style scoped>
.filtraPeriodoContainer {
  align-items: left;
  align-content: left;
  justify-items: left;
  justify-content: left;
}

.panel-heading {
  text-align: left;
}

.table thead tr th div .th-wrap > span .MIACLASSE {
  align-content: stretch;
}

.centralPanel {
  align-items: flex-start;
}

.has-importo-positivo {
  color: #1bb551;
}

.has-importo-negativo {
  color: #dd2b4f;
}
</style>
