
import Cleave from 'cleave.js'

const cleave = {
  name: 'cleave',
  bind(el, binding) {
    const input = el.querySelector('input')
    input._vCleave = new Cleave(input, binding.value)
  },
  unbind(el) {
    const input = el.querySelector('input')
    input._vCleave.destroy()
  }
}

export default {
  directives: { cleave },
  data() {
    return {
      masks: {
        money: {
          numeral: true,
          delimited: ',',
          numeralThousandsGroupStyle: 'none',
          numeralDecimalMark: ',',
          numeralDecimalScale: 2,
          numeralPositiveOnly: true
        },
        movimento: {
          numeral: true,
          delimited: ',',
          numeralThousandsGroupStyle: 'none',
          numeralDecimalMark: ',',
          numeralDecimalScale: 2,
          numeralPositiveOnly: false
        }
      }
    }
  }
}
