<template>
  <b-modal :active.sync="show" has-modal-card>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Seleziona Categoria</p>
      </header>
      <section class="modal-card-body">
        <div class="columns is-centered">
          <template v-for="indexSlice in size">
            <p :key="`p-${indexSlice}`" class="column">
              <template v-for="(categoria, index) in takeCategorie(indexSlice)">
                <b-field :key="`control-${indexSlice}-${index}`">
                  <p :key="`div-cat-${indexSlice}-${index}`" class="control">
                    <b-button
                      :type="countElementsCategory((indexSlice-1)*size+index) > 0 ? 'is-primary' : 'is-light'"
                      :key="`cat-${indexSlice}-${index}`"
                      @click="setCategoria((indexSlice-1)*size+index)"
                      :disabled="countElementsCategory((indexSlice-1)*size+index) <= 0"
                    >{{ categoria.name }}</b-button>
                  </p>
                  <p :key="`p-count-${indexSlice}-${index}`" class="control">
                    <b-button
                      disabled
                      :key="`count-${indexSlice}-${index}`"
                    >{{countElementsCategory((indexSlice-1)*size+index)}}</b-button>
                  </p>
                </b-field>
              </template>
            </p>
          </template>
        </div>
      </section>
      <!-- <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">Close</button>
      </footer>-->
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "selectCategoriaFilter",
  props: ["showFlag", "filteredData"],
  data: function() {
    return {
      size: 6
    };
  },
  computed: {
    show: {
      get() {
        return this.showFlag;
      },
      set(value) {
        this.$emit("update:showFlag", value);
      }
    }
  },
  methods: {
    takeCategorie(slice) {
      let begin = this.size * (slice - 1);
      let end =
        slice == this.size
          ? this.$store.state.categorie.length
          : begin + this.size;
      return this.$store.state.categorie.slice(begin, end);
    },
    setCategoria(categoriaIndex) {
      this.$emit("selectedCategoria", categoriaIndex);
      this.$emit("close");
    },
    countElementsCategory(categoriaIndex) {
      return this.filteredData.reduce(
        (prev, curr) => (curr.categoria == categoriaIndex ? prev + 1 : prev),
        0
      );
    }
  }
};
</script>

<style scoped>
.tags .tag {
  cursor: pointer;
}
</style>