<script>
import moment from "moment";
import CheBanca from "@/components/import/loaders/CheBanca.vue";

function readCell(cell) {
  let retValue = cell.v;
  if (cell.w != undefined) {
    retValue = cell.w;
  }
  return retValue;
}

function readCellAsDate(cell) {
  return moment(
    readCell(cell),
    [
      "DD-MM-YYYY",
      "DD/MM/YYYY",
      "MM-DD-YYYY",
      "MM/DD/YYYY",
      "DD-MM-YY",
      "DD/MM/YY",
      "MM-DD-YY",
      "MM/DD/YY",

      // day 1 digit, month 2
      "D-MM-YYYY",
      "D/MM/YYYY",
      "MM-D-YYYY",
      "MM/D/YYYY",
      "D-MM-YY",
      "D/MM/YY",
      "MM-D-YY",
      "MM/D/YY",

      // day 1 digit, month 1
      "D-M-YYYY",
      "D/M/YYYY",
      "M-D-YYYY",
      "M/D/YYYY",
      "D-M-YY",
      "D/M/YY",
      "M-D-YY",
      "M/D/YY",

      // day 2 digit, month 1
      "DD-M-YYYY",
      "DD/M/YYYY",
      "M-DD-YYYY",
      "M/DD/YYYY",
      "DD-M-YY",
      "DD/M/YY",
      "M-DD-YY",
      "M/DD/YY"
    ],
    true
  );
}

function groupBy(data, selector, groupingFunc) {
  return data.reduce(function(grouped, current) {
    const value = selector(current);

    grouped[value] = JSON.parse(
      JSON.stringify(groupingFunc(grouped[value], current))
    );

    return grouped;
  }, {});
}
let isNumber = function(cell) {
  return (
    cell != undefined &&
    readCell(cell) != undefined &&
    !isNaN(Number(String(readCell(cell)).replace(",", "."))) &&
    isFinite(Number(String(readCell(cell)).replace(",", ".")))
  );
};

let isImporto = function(cell) {
  return (
    cell != undefined &&
    readCell(cell) != undefined &&
    !isNaN(
      Number(
        String(readCell(cell))
          .replace(",", ".")
          .replace("€", "")
          .replace("Euro", "")
          .trim()
      )
    ) &&
    isFinite(
      Number(
        String(readCell(cell))
          .replace(",", ".")
          .replace("€", "")
          .replace("Euro", "")
          .trim()
      )
    )
  );
};

let readCellAsNumber = function(cell) {
  return Number(String(readCell(cell)).replace(",", "."));
};

let readCellAsImporto = function(cell) {
  return Number(
    String(readCell(cell))
      .replace(",", ".")
      .replace("€", "")
      .replace("Euro", "")
      .trim()
  );
};

let isDate = function(cell) {
  return cell != undefined && readCellAsDate(cell).isValid();
};

function lookForSaldo(sheet, references) {
  console.log("Looking for Saldo...");
  /**
   * Cerca candidati
   */
  let candidates = Object.keys(sheet).filter(
    key =>
      !key.startsWith("!") &&
      sheet[key] != undefined &&
      readCell(sheet[key]) != undefined &&
      String(readCell(sheet[key]))
        .toLowerCase()
        .includes("saldo")
  );

  function reduceCandidate(candidates) {
    let reducedCandidates = candidates;
    let displacement = "none";
    if (candidates.length > 1) {
      console.log(
        "Too many candidates",
        candidates.length,
        "going to reduce.."
      );
      let groupedByColumn = groupBy(
        candidates,
        cell => cell[0],
        (previous, current) => (previous != undefined ? previous + 1 : 1)
      );
      if (Object.keys(groupedByColumn).length > 1) {
        console.log("horizontal displacement");
        displacement = "horizontal";
        let groupedByRow = groupBy(
          candidates,
          cell => cell.substring(1),
          (previous, current) => (previous != undefined ? previous + 1 : 1)
        );

        let column = candidates.map(c => String(c[0]).charCodeAt());
        let rows = Object.keys(groupedByRow)[0];
        reducedCandidates = [
          String.fromCharCode(Math.min.apply(null, column)) + rows,
          String.fromCharCode(Math.max.apply(null, column)) + rows
        ];
      } else {
        console.log("vertical displacement");
        displacement = "vertical";
        let column = Object.keys(groupedByColumn)[0];
        let rows = candidates.map(c => Number(c.substring(1)));
        reducedCandidates = [
          column + String(Math.min.apply(null, rows)),
          column + String(Math.max.apply(null, rows))
        ];
      }
    }
    return {
      reducedCandidates: reducedCandidates,
      displacement: displacement
    };
  }

  /**
   * Riduci candidati a massimo 2
   */
  let { reducedCandidates, displacement } = reduceCandidate(candidates);
  console.log("Saldo candidates", reducedCandidates, displacement);

  /**
   * Strategie:
   * - Saldo disponibile
   * - Saldo finale
   * - Saldo 'al'
   */
  let findSaldoCell = function(candidates) {
    let saldoDisponibileKey = candidates.findIndex(key =>
      String(readCell(sheet[key]))
        .toLowerCase()
        .includes("disponibile")
    );
    if (saldoDisponibileKey != -1) {
      console.log("Strategy: Saldo disponibile");
      return candidates[saldoDisponibileKey];
    }

    let saldoFinaleKey = candidates.findIndex(key =>
      String(readCell(sheet[key]))
        .toLowerCase()
        .includes("finale")
    );
    if (saldoFinaleKey != -1) {
      console.log("Strategy: Saldo finale");
      return candidates[saldoFinaleKey];
    }

    let regex = /al (\d{2}[\/-]\d{2}[\/-]\d{4})/;
    let saldoAlKeys = candidates.filter(key =>
      String(readCell(sheet[key]))
        .toLowerCase()
        .match(regex)
    );
    if (saldoAlKeys.length == 1) {
      console.log("Strategy: Saldo al, singolo");
      return candidates[saldoAlKey[0]];
    } else {
      console.log("Strategy: Saldo al, doppio");
      let isFirst = moment(
        String(readCell(sheet[candidates[0]]))
          .toLowerCase()
          .match(regex)[1],
        "DD/MM/YYYY"
      ).isSameOrAfter(
        moment(
          String(readCell(sheet[candidates[1]]))
            .toLowerCase()
            .match(regex)[1],
          "DD/MM/YYYY"
        )
      );
      return candidates[isFirst ? 0 : 1];
    }
  };

  let saldoKey;
  try {
    console.log("Search saldo among", reducedCandidates);
    saldoKey = findSaldoCell(reducedCandidates);
  } catch (err) {
    console.log(
      "Failed",
      err,
      "Search saldo among full candidates",
      candidates
    );
    saldoKey = findSaldoCell(candidates);
  }
  console.log(saldoKey);

  /**
   * Cerca importo:
   * - in fondo alla stessa cella
   * - nelle celle intorno secondo displacement
   */
  let readSaldo = function(saldoCellKey, displacement) {
    let insideCellRegex = /. (\d+[,.]*\d*)$/;
    let matchesSimpleInside = String(readCell(sheet[saldoCellKey]))
      .replace(",", ".")
      .match(insideCellRegex);
    if (matchesSimpleInside != null) {
      console.log("Inside");
      return Number(matchesSimpleInside[1]);
    }

    let stringCellContent = String(readCell(sheet[saldoCellKey]));
    if (stringCellContent.indexOf(":") > -1) {
      let cleanStringContent = stringCellContent
        .substring(stringCellContent.indexOf(":") + 1)
        .replace(",", ".")
        .toLowerCase()
        .replace("euro", "")
        .trim();
      let insideInlineCellRegex = /[+-]*(\d+[,.]*\d*)$/;
      let matchesInlineInside = cleanStringContent.match(insideInlineCellRegex);
      if (matchesInlineInside != null) {
        console.log("Inside but inline");
        return parseFloat(matchesInlineInside[1]);
      }
    }

    let cellOnRight =
      String.fromCharCode(String(saldoCellKey[0]).charCodeAt() + 1) +
      saldoCellKey.substring(1);
    let cellOnLeft =
      String.fromCharCode(String(saldoCellKey[0]).charCodeAt() - 1) +
      saldoCellKey.substring(1);
    let cellOnUp =
      saldoCellKey[0] + String(Number(saldoCellKey.substring(1)) - 1);
    let cellOnDown =
      saldoCellKey[0] + String(Number(saldoCellKey.substring(1)) + 1);

    switch (displacement) {
      case "vertical":
        {
          if (isNumber(sheet[cellOnRight])) {
            console.log("Found on right");
            return readCellAsNumber(sheet[cellOnRight]);
          } else if (isNumber(sheet[cellOnLeft])) {
            console.log("Found on left");
            return readCellAsNumber(sheet[cellOnLeft]);
          }
        }
        break;
      case "horizontal":
        {
          if (isNumber(sheet[cellOnUp])) {
            console.log("Found on up");
            return readCellAsNumber(sheet[cellOnUp]);
          } else if (isNumber(sheet[cellOnDown])) {
            console.log("Found on down");
            return readCellAsNumber(sheet[cellOnDown]);
          }
        }
        break;
      case "none":
        {
          if (isNumber(sheet[cellOnRight])) {
            console.log("Found on right");
            return readCellAsNumber(sheet[cellOnRight]);
          } else if (isNumber(sheet[cellOnLeft])) {
            console.log("Found on left");
            return readCellAsNumber(sheet[cellOnLeft]);
          } else if (isNumber(sheet[cellOnUp])) {
            console.log("Found on up");
            return readCellAsNumber(sheet[cellOnUp]);
          } else if (isNumber(sheet[cellOnDown])) {
            console.log("Found on down");
            return readCellAsNumber(sheet[cellOnDown]);
          }
        }
        break;
    }
  };

  let saldo = readSaldo(saldoKey, displacement);
  console.log("Looking for Saldo... Done!");
  return saldo;
}

function lookForDati(sheet, references) {
  console.log("Looking for movimenti...");

  let numberColumnStart = String(references.column.start).charCodeAt();
  let numberColumnEnd = String(references.column.end).charCodeAt();

  let findReferencesRowTable = function(sheet, references) {
    // Heuristic for finding table, using first 20 rows
    let sampleRows = [...Array(20).keys()].map(row => {
      let wide = 0;
      for (let i = numberColumnStart; i <= numberColumnEnd; i++) {
        let cell = sheet[String.fromCharCode(i) + String(row)];
        if (
          cell != undefined &&
          readCell(cell) != undefined &&
          String(readCell(cell)).trim() != ""
        ) {
          wide = i - numberColumnStart;
        }
      }
      return wide;
    });

    let maxWide = Math.max(...sampleRows);
    let startRow = sampleRows.findIndex(e => e == maxWide);

    // Check skip Saldo start
    let isStartRowAsSaldo = [
      ...Array(numberColumnEnd - numberColumnStart + 1).keys()
    ].some(i => {
      let cell =
        sheet[String.fromCharCode(i + numberColumnStart) + String(startRow)];
      return (
        cell != undefined &&
        readCell(cell) != undefined &&
        String(readCell(cell))
          .toLowerCase()
          .includes("saldo")
      );
    });
    if (isStartRowAsSaldo) {
      console.log("Skipping first Saldo row");
      startRow = startRow + 1;
    }

    // Check skip Header
    let isStartRowAsHeaderTable = [
      ...Array(numberColumnEnd - numberColumnStart + 1).keys()
    ].every(i => {
      let cell =
        sheet[String.fromCharCode(i + numberColumnStart) + String(startRow)];
      return !isNumber(cell);
    });
    if (isStartRowAsHeaderTable) {
      console.log("Skipping first Header Table row");
      startRow = startRow + 1;
    }

    let endRow = Number(references.row.end);
    let found = false;

    for (
      let indexRow = startRow;
      indexRow <= Number(references.row.end) && !found;
      indexRow++
    ) {
      found = [...Array(numberColumnEnd - numberColumnStart + 1).keys()].every(
        indexColumn => {
          let cell =
            sheet[
              String.fromCharCode(indexColumn + numberColumnStart) +
                String(indexRow)
            ];
          return (
            cell == undefined ||
            readCell(cell) == undefined ||
            String(readCell(cell)) == ""
          );
        }
      );
      if (found) {
        endRow = indexRow - 1;
      }
    }

    // Check skip Saldo end
    let isEndRowAsSaldo = [
      ...Array(numberColumnEnd - numberColumnStart + 1).keys()
    ].some(i => {
      let cell =
        sheet[String.fromCharCode(i + numberColumnStart) + String(endRow)];
      return (
        cell != undefined &&
        readCell(cell) != undefined &&
        String(readCell(cell))
          .toLowerCase()
          .includes("saldo")
      );
    });
    if (isEndRowAsSaldo) {
      console.log("Skipping end Saldo row");
      endRow = endRow - 1;
    }

    return [startRow, endRow];
  };

  let referencesRowTable = findReferencesRowTable(sheet, references);
  console.log("Table at", referencesRowTable);

  // Trim columns
  let stopStartTrim = false;
  let howManyToSkipStart = 0;
  for (
    let indexColumn = numberColumnStart;
    indexColumn < numberColumnEnd && !stopStartTrim;
    indexColumn++
  ) {
    stopStartTrim = [
      ...Array(
        referencesRowTable[1] - referencesRowTable[0] + 1 > 20
          ? 20
          : referencesRowTable[1] - referencesRowTable[0] + 1
      ).keys()
    ].some(i => {
      let cell =
        sheet[
          String.fromCharCode(indexColumn) + String(referencesRowTable[0] + i)
        ];
      return cell != undefined;
    });
    if (stopStartTrim) {
      howManyToSkipStart = indexColumn - numberColumnStart;
    }
  }
  numberColumnStart = numberColumnStart + howManyToSkipStart;

  let stopEndTrim = false;
  let howManyToSkipEnd = 0;
  for (
    let indexColumn = numberColumnEnd;
    indexColumn > numberColumnStart && !stopEndTrim;
    indexColumn--
  ) {
    stopEndTrim = [
      ...Array(
        referencesRowTable[1] - referencesRowTable[0] + 1 > 20
          ? 20
          : referencesRowTable[1] - referencesRowTable[0] + 1
      ).keys()
    ].some(i => {
      let cell =
        sheet[
          String.fromCharCode(indexColumn) + String(referencesRowTable[0] + i)
        ];
      return cell != undefined;
    });
    if (stopEndTrim) {
      howManyToSkipEnd = numberColumnEnd - indexColumn;
    }
  }
  numberColumnEnd = numberColumnEnd - howManyToSkipEnd;

  // TODO: label columns then scan table accordingly for final looup and populate final array

  let labelColumns = function() {
    // Heuristic for labelling column, using first 10 rows of the table
    let labels = {
      data_contabile: null,
      data_valuta: null,
      descrizione: null,
      importo: []
    };

    let descrizioneCandidates = [
      ...Array(numberColumnEnd - numberColumnStart + 1).keys()
    ].map(e => 0);

    let importoCandidates = [
      ...Array(numberColumnEnd - numberColumnStart + 1).keys()
    ].map(e => null);

    let FLAG_NO_DATE = 4; // 100
    let FLAG_NOT_ALWAYS_DEFINED = 2; // 010
    let FLAG_NOT_ALWAYS_UNDEFINED = 1; // 001
    let FLAG_INIT = 0;
    let datesCandidates = [
      ...Array(numberColumnEnd - numberColumnStart + 1).keys()
    ].map(e => FLAG_INIT);

    for (
      let indexColumn = numberColumnStart;
      indexColumn <= numberColumnEnd;
      indexColumn++
    ) {
      [
        ...Array(
          referencesRowTable[1] - referencesRowTable[0] + 1 > 200
            ? 200
            : referencesRowTable[1] - referencesRowTable[0] + 1
        ).keys()
      ].forEach(indexRow => {
        let cell =
          sheet[
            String.fromCharCode(indexColumn) +
              String(referencesRowTable[0] + indexRow)
          ];

        // Heuristic for importo
        if (
          cell != undefined &&
          !isImporto(cell) &&
          importoCandidates[indexColumn - numberColumnStart] != null
        ) {
          importoCandidates[indexColumn - numberColumnStart] = null;
        } else if (
          isImporto(cell) &&
          importoCandidates[indexColumn - numberColumnStart] != "mixed"
        ) {
          let becomeMixed = false;
          switch (importoCandidates[indexColumn - numberColumnStart]) {
            case "positive":
              becomeMixed = readCellAsNumber(cell) < 0;
              break;
            case "negative":
              becomeMixed = readCellAsNumber(cell) > 0;
              break;
            case null: // initialise
              importoCandidates[indexColumn - numberColumnStart] =
                readCellAsImporto(cell) < 0 ? "negative" : "positive";
              break;
          }
          if (becomeMixed) {
            importoCandidates[indexColumn - numberColumnStart] = "mixed";
          }
        }

        // Heuristic for causale
        if (
          cell != undefined &&
          readCell(cell) != undefined &&
          String(readCell(cell)).trim() != ""
        ) {
          descrizioneCandidates[indexColumn - numberColumnStart] = Math.max(
            descrizioneCandidates[indexColumn - numberColumnStart],
            String(readCell(cell)).length
          );
        }

        // Heuristic for dates
        if (
          cell == undefined ||
          readCell(cell) == undefined ||
          readCell(cell) == ""
        ) {
          datesCandidates[
            indexColumn - numberColumnStart
          ] |= FLAG_NOT_ALWAYS_DEFINED;
        }
        if (cell != undefined) {
          datesCandidates[
            indexColumn - numberColumnStart
          ] |= FLAG_NOT_ALWAYS_UNDEFINED;
        }
        if (cell != undefined && readCell(cell) != "" && !isDate(cell)) {
          datesCandidates[indexColumn - numberColumnStart] |= FLAG_NO_DATE;
        }
      });
    }

    /**
     * Causale è la lunghezza massima
     */
    labels.descrizione = String.fromCharCode(
      numberColumnStart +
        descrizioneCandidates.indexOf(Math.max(...descrizioneCandidates))
    );
    /**
     * per trovare importo scorrere per colonna tutte le righe e trovare quelle che hanno sempre solo numeri (o vuoto).
     * se 1 colonna allora facile
     * se 2 colonne: controllare (ricordarsi sopra) se entrambe le colonne sono con numeri positivi o meno.
     *  se uno positivo e l'altro negativo: facile
     *  se entrambi positivi, andare nell'intestazione della tabella (che deve esserci obbligatoriamente) e leggere "entrate"/"uscite"
     */
    if (importoCandidates.filter(e => e != null).length == 1) {
      console.log("Only one column as candidate for importo");
      labels.importo = [
        String.fromCharCode(
          numberColumnStart +
            importoCandidates.indexOf(
              importoCandidates.filter(e => e != null)[0]
            )
        )
      ];
    } else if (importoCandidates.filter(e => e != null).length == 2) {
      console.log("Found 2 columns as importo");
      let firstColumn = String.fromCharCode(
        numberColumnStart +
          importoCandidates.indexOf(importoCandidates.filter(e => e != null)[0])
      );
      let secondColumn = String.fromCharCode(
        numberColumnStart +
          importoCandidates.lastIndexOf(
            importoCandidates.filter(e => e != null)[1]
          )
      );

      // Assumo che la prima colonna sia entrate e la seconda uscita. Swappo se header mi smentisce
      // Assumo che esiste l'header della tabella
      let firstHeaderCell =
        sheet[firstColumn + String(referencesRowTable[0] - 1)];
      let secondHeaderCell =
        sheet[secondColumn + String(referencesRowTable[0] - 1)];

      let firstHeaderCellNormalised = String(
        readCell(firstHeaderCell)
      ).toLowerCase();
      let secondHeaderCellNormalised = String(
        readCell(secondHeaderCell)
      ).toLowerCase();
      if (
        firstHeaderCellNormalised.includes("uscite") ||
        firstHeaderCellNormalised.includes("addebiti") ||
        secondHeaderCellNormalised.includes("entrate") ||
        secondHeaderCellNormalised.includes("accrediti")
      ) {
        let tmpColumn = firstColumn;
        firstColumn = secondColumn;
        secondColumn = tmpColumn;
      }
      labels.importo = [firstColumn, secondColumn];
    } else {
      console.log(importoCandidates);
      throw "Troppe colonne candidate per essere importo!";
    }

    /**
     * Heuristic for data_valuta and data_contabile
     *
     * trovare colonne che sono sempre solo date (o vuote)
     * se trovo una colonna allora varrà sia per una data che per l'altra basta che sia sempre definita
     * altrimenti devo trovare esattamente 2 colonne
     * se mentre scorro per riga vedo che una colonna è completamente avvalorata mentre l'altra no allora ho capito quale è quella contabile
     * se entrambe sono sempre avvalorate allora controllo se una colonna presenta date sempre maggiori dell'altra
     * se sono sempre la stessa data allora assumo esista header e controllo se c'è "contabile"/"valuta"
     */
    if (
      datesCandidates.filter(d => d == FLAG_NOT_ALWAYS_UNDEFINED).length == 1 &&
      datesCandidates.filter(
        d =>
          d == FLAG_NOT_ALWAYS_UNDEFINED ||
          FLAG_NOT_ALWAYS_UNDEFINED | FLAG_NOT_ALWAYS_DEFINED
      ).length == 0
    ) {
      // Solo una colonna sempre definita
      console.log("Just one date column found");
      let dataColumn = String.fromCharCode(
        numberColumnStart +
          datesCandidates.indexOf(
            datesCandidates.filter(
              d =>
                d == FLAG_NOT_ALWAYS_UNDEFINED ||
                FLAG_NOT_ALWAYS_UNDEFINED | FLAG_NOT_ALWAYS_DEFINED
            )
          )
      );
      labels.data_contabile = dataColumn;
      labels.data_valuta = dataColumn;
    } else if (
      datesCandidates.filter(
        d =>
          d == FLAG_NOT_ALWAYS_UNDEFINED ||
          d == (FLAG_NOT_ALWAYS_UNDEFINED | FLAG_NOT_ALWAYS_DEFINED)
      ).length == 2
    ) {
      // 2 colonne
      // Assumo A data_contabile  e B data_valuta
      console.log("FOUND 2 canditate columns for dates");
      let valoreColonnaA = datesCandidates.filter(
        d =>
          d == FLAG_NOT_ALWAYS_UNDEFINED ||
          d == (FLAG_NOT_ALWAYS_UNDEFINED | FLAG_NOT_ALWAYS_DEFINED)
      )[0];
      let indiceA = null;
      let valoreColonnaB = datesCandidates.filter(
        d =>
          d == FLAG_NOT_ALWAYS_UNDEFINED ||
          d == (FLAG_NOT_ALWAYS_UNDEFINED | FLAG_NOT_ALWAYS_DEFINED)
      )[1];
      let indiceB = null;
      if (
        datesCandidates.filter(d => d == FLAG_NOT_ALWAYS_UNDEFINED).length == 1
      ) {
        // Solo una sempre avvalorata
        console.log("One of them is always set");
        if (
          valoreColonnaA ==
          datesCandidates.filter(d => d == FLAG_NOT_ALWAYS_UNDEFINED)[0]
        ) {
          indiceA = datesCandidates.indexOf(valoreColonnaB);
          indiceB = datesCandidates.indexOf(valoreColonnaA);
        } else {
          indiceA = datesCandidates.indexOf(valoreColonnaA);
          indiceB = datesCandidates.indexOf(valoreColonnaB);
        }
      } else if (
        datesCandidates.filter(d => d == FLAG_NOT_ALWAYS_DEFINED).length == 2 ||
        datesCandidates.filter(
          d => d == (FLAG_NOT_ALWAYS_UNDEFINED | FLAG_NOT_ALWAYS_DEFINED)
        ).length == 2
      ) {
        console.log("Colonne non complete per le colonne cadidate come date");
      } else {
        console.log("Both of them are always set");
        // Sempre entrambe avvalorate
        // Necessario controllare se una data è sempre maggiore dell'altra
        let a_alwaysGreater = true;
        let b_alwaysGreater = true;
        [
          ...Array(
            referencesRowTable[1] - referencesRowTable[0] + 1 > 10
              ? 10
              : referencesRowTable[1] - referencesRowTable[0] + 1
          ).keys()
        ].forEach(indexRow => {
          let dateA = readCellAsDate(
            sheet[
              String.fromCharCode(
                numberColumnStart + datesCandidates.indexOf(valoreColonnaA)
              ) + String(referencesRowTable[0] + indexRow)
            ]
          );
          let dateB = readCellAsDate(
            sheet[
              String.fromCharCode(
                numberColumnStart + datesCandidates.lastIndexOf(valoreColonnaB)
              ) + String(referencesRowTable[0] + indexRow)
            ]
          );
          if (dateA.isAfter(dateB)) {
            b_alwaysGreater = false;
          } else if (dateB.isAfter(dateA)) {
            a_alwaysGreater = false;
          }
        });
        if (b_alwaysGreater && !a_alwaysGreater) {
          indiceA = datesCandidates.lastIndexOf(valoreColonnaB);
          indiceB = datesCandidates.indexOf(valoreColonnaA);
        } else if (!b_alwaysGreater && a_alwaysGreater) {
          indiceA = datesCandidates.indexOf(valoreColonnaA);
          indiceB = datesCandidates.lastIndexOf(valoreColonnaB);
        } else if (b_alwaysGreater && a_alwaysGreater) {
          // sono sempre uguali, guardo l'intestazione della tabella
          let firstHeaderCell =
            sheet[
              String.fromCharCode(
                numberColumnStart + datesCandidates.indexOf(valoreColonnaA)
              ) + String(referencesRowTable[0] - 1)
            ];
          if (
            String(readCell(firstHeaderCell))
              .toLowerCase()
              .includes("valuta")
          ) {
            indiceA = datesCandidates.lastIndexOf(valoreColonnaB);
            indiceB = datesCandidates.indexOf(valoreColonnaA);
          }
        } else {
          indiceA = datesCandidates.indexOf(valoreColonnaA);
          indiceB = datesCandidates.lastIndexOf(valoreColonnaB);
        }
      }

      labels.data_contabile = String.fromCharCode(numberColumnStart + indiceA);
      labels.data_valuta = String.fromCharCode(numberColumnStart + indiceB);
    } else {
      throw "Troppe o nessuna colonna candidate per essere data contabile e data valuta!";
    }

    return labels;
  };

  let labels = labelColumns();
  console.log("Columns labelled as", labels);

  // FINALLY... Scan the table and load actual data
  let dati = [];
  for (
    let indexRow = referencesRowTable[0];
    indexRow <= referencesRowTable[1];
    indexRow++
  ) {
    let dataContabileCell = sheet[labels.data_contabile + indexRow];

    let importoCells = labels.importo.map(importo => {
      let importoCell = sheet[importo + indexRow];
      if (importoCell != undefined) return readCell(importoCell);
      else return null;
    });
    let importo = null;
    if (
      importoCells.length == 1 ||
      (importoCells[0] != null && importoCells[0] != "")
    ) {
      importo = Number(
        String(importoCells[0])
          .replace(",", ".")
          .replace("€", "")
          .replace("Euro", "")
          .trim()
      );
    } else {
      importo = Number(
        String(importoCells[1])
          .replace(",", ".")
          .replace("€", "")
          .replace("Euro", "")
          .trim()
      );
      if (importo > 0) {
        importo = importo * -1;
      }
    }

    let descrizioneCell = sheet[labels.descrizione + indexRow];
    dati.push({
      data_contabile:
        dataContabileCell != undefined && readCell(dataContabileCell) != ""
          ? readCellAsDate(dataContabileCell).toDate()
          : null,
      data_valuta: readCellAsDate(
        sheet[labels.data_valuta + indexRow]
      ).toDate(),
      importo: importo,
      descrizione:
        descrizioneCell != undefined ? String(readCell(descrizioneCell)) : null
    });
  }

  console.log("Looking for movimenti... Done!");
  return dati;
}

export default {
  load(workbook) {
    console.log(workbook);
    try {
      let sheet = workbook.Sheets[workbook.SheetNames[0]];

      let indexes = sheet["!ref"].split(":");

      let references = {
        column: {
          start: indexes[0][0],
          end: indexes[1][0]
        },
        row: {
          start: indexes[0].substring(1),
          end: indexes[1].substring(1)
        }
      };

      let saldo = undefined;
      try {
        saldo = lookForSaldo(sheet, references);
        console.log("Saldo", saldo);
        if (saldo == undefined || saldo == null) {
          throw "Not valid saldo";
        }
      } catch (error) {
        console.log("Impossibile individuare il saldo", error);
        saldo = 0;
      }

      let dati = lookForDati(sheet, references);
      console.log("Loaded", dati.length);

      return { dati: dati, saldo: saldo };
    } catch (error) {
      throw error;
    }
  }
};
</script>