<template>
  <section class="section">
    <b-message type="is-primary is-size-7">
      <p>Carica i movimenti della tua banca esportati in formato Excel.</p>
      <p>
        <strong>Ricorda: i tuoi dati non vengono inviati a nessuno, sono caricati unicamente sul tuo browser.</strong>
      </p>
      <br />
      <p>
        Se è impossibile leggere il file prova invece ad utilizzare il nostro
        <a
          href="/ComeSto_Template_v1.0.xls"
          title="Scarica template"
        >template:</a>&nbsp;
        <a href="/ComeSto_Template_v1.0.xls" title="Scarica template">SCARICA</a>
      </p>
    </b-message>
    <b-field>
      <b-upload
        class="dd-upload"
        v-model="dropFiles"
        @input="loadFile"
        type="is-success"
        :loading="loading"
        native
        drag-drop
        required
      >
        <section class="section">
          <div class="content has-text-centered">
            <p>
              <b-icon icon="upload" size="is-medium"></b-icon>
            </p>
            <p>Trascina o clicca per caricare</p>
          </div>
        </section>
      </b-upload>
    </b-field>

    <b-field class="is-centered">
      <b-radio-button v-model="saldoPolicy" native-value="update" type="is-primary">
        <b-icon icon="newspaper"></b-icon>
        <span>Aggiorna Saldo</span>
      </b-radio-button>

      <b-radio-button v-model="saldoPolicy" native-value="sum" type="is-warning">
        <b-icon icon="newspaper-plus"></b-icon>
        <span>Somma Saldi</span>
      </b-radio-button>
      <b-tooltip label="Opzione utile in caso di caricamento da più conti">
        <b-icon class="control" icon="information" size="is-small"></b-icon>
      </b-tooltip>
    </b-field>
  </section>
</template>

<script>
import XLSX from "xlsx";
import SmartLoad from "@/components/import/loaders/SmartLoad.vue";
import moment from "moment";
import { async } from "q";

export default {
  name: "loadExcel",
  data() {
    return {
      dropFiles: null,
      loading: false,
      saldoPolicy: "update"
    };
  },
  methods: {
    loadFile(file) {
      this.loading = true;
      var reader = new FileReader();

      let self = this;
      reader.onload = function(e) {
        try {
          var data = e.target.result;
          var workbook = XLSX.read(data, {
            type: "binary"
          });

          let loadedObj = SmartLoad.load(workbook);
          loadedObj.isSaldoPolicyToBeSummed = self.saldoPolicy == "sum";
          self.$store.dispatch("loadDati", loadedObj);

          self.showLoaded(loadedObj.dati.length);
          self.$emit("loaded");
        } catch (ex) {
          self.showLoadingError(ex);
        }
      };

      reader.onerror = this.showLoadingError;

      reader.readAsBinaryString(file);
    },
    showLoadingError(ex) {
      console.log(ex);
      this.loading = false;
      this.$buefy.toast.open({
        duration: 5000,
        message: "Impossibile leggere il file",
        position: "is-bottom",
        type: "is-danger"
      });
    },
    showLoaded(total) {
      this.loading = false;
      this.$buefy.toast.open({
        duration: 5000,
        message: "Caricati " + total + " movimenti con successo",
        position: "is-bottom",
        type: "is-success"
      });
    }
  }
};
</script>